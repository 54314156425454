<template>
  <div v-if="permission.access">
    <div class="w-100 text-right">
      <b-row class="justify-content-between mb-2">
        <b-col
          cols="12"
          md="4"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            @on-change="paginateChange"
            :options="perPageOptions"
            v-model="search.paginate"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="4">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="search.search_data"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
            <b-dropdown
              text="Action"
              variant="outline-primary"
              :disabled="!select.selected"
            >
              <b-dropdown-item @click="Delete"> Delete </b-dropdown-item>
              <b-dropdown-item
                @click="batchAssignModal = true"
                v-if="search.course_id && search.batch_id == 'No Batch Assign'"
              >
                Assign Batch
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-col>
      </b-row>
    </div>
    <Modal
      v-model="batchAssignModal"
      title="Assign Batch"
      @on-ok="assingBatch"
      ok-text="Assign"
    >
      <b-col md="12">
        <div class="form-group">
          <Select
            name="batch_id"
            id="batch_id"
            placeholder="Select Batch"
            v-model="bacth_id"
          >
            <Option
              v-for="(batche, index) in batches"
              :key="index"
              v-if="batches.length"
              :value="batche.id"
              >{{ batche.name }}</Option
            >
          </Select>
        </div>
      </b-col>
    </Modal>
    <div class="row">
      <div class="col-md-12">
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col md="2">
                <div class="form-group">
                  <Select
                    v-model="search.is_complete"
                    filterable
                    @on-change="SearchData"
                    :clearable="true"
                    name="is_complete"
                    id="is_complete"
                    placeholder="Is Complete"
                  >
                    <Option :value="1">Complete</Option>
                    <Option :value="2">Not Complete</Option>
                  </Select>
                </div>
              </b-col>
              <b-col md="3">
                <div class="form-group">
                  <Select
                    v-model="search.course_id"
                    filterable
                    @on-change="SearchData"
                    :clearable="true"
                    name="course_id"
                    id="course_id"
                    placeholder="Select Purchase Coruse"
                  >
                    <Option
                      :value="course.id"
                      v-for="(course, index) in courses"
                      :key="index"
                      v-if="courses.length"
                      >{{ course.name }}</Option
                    >
                  </Select>
                </div>
              </b-col>
              <b-col md="3">
                <div class="form-group">
                  <Select
                    name="batch_id"
                    id="batch_id"
                    placeholder="Select Batch"
                    v-model="search.batch_id"
                    filterable
                    @on-change="SearchData"
                    :clearable="true"
                  >
                    <Option value="No Batch Assign">No Assign Batch</Option>
                    <Option
                      v-for="(batche, index) in batches"
                      :key="index"
                      v-if="batches.length"
                      :value="batche.id"
                      >{{ batche.name }}</Option
                    >
                  </Select>
                </div>
              </b-col>
              <b-col md="2">
                <div class="form-group">
                  <Select
                    v-model="search.teacher_id"
                    filterable
                    @on-change="SearchData"
                    :clearable="true"
                    name="teacher_id"
                    id="teacher_id"
                    placeholder="Select teacher "
                  >
                    <Option
                      :value="teacher.id"
                      v-for="(teacher, index) in teachers"
                      :key="index"
                      v-if="teachers.length"
                      >{{ teacher.name }}</Option
                    >
                  </Select>
                </div>
              </b-col>
              <b-col md="2">
                <div class="form-group">
                  <Select
                    v-model="search.payment_method"
                    filterable
                    @on-change="SearchData"
                    :clearable="true"
                    name="payment_method"
                    id="payment_method"
                    placeholder="Payment method"
                  >
                    <Option :value="1">SSLCommerz </Option>
                    <Option :value="2">b-Kash </Option>
                    <Option :value="3">Nogod</Option>
                  </Select>
                </div>
              </b-col>

              <b-col md="3">
                <div class="form-group">
                  <DatePicker
                    format="yyyy-MM-dd"
                    type="date"
                    name="from_date"
                    v-model="search.from_date"
                    id="from_date"
                    placeholder="Created From Date"
                    :clearable="true"
                    style="width: 100%"
                  ></DatePicker>
                </div>
              </b-col>
              <b-col md="3">
                <div class="form-group">
                  <DatePicker
                    format="yyyy-MM-dd"
                    type="date"
                    name="to_date"
                    v-model="search.to_date"
                    id="to_date"
                    placeholder="Created To Date"
                    :clearable="true"
                    style="width: 100%"
                  ></DatePicker>
                </div>
              </b-col>
              <b-col md="2">
                <div class="form-group">
                  <DatePicker
                    format="yyyy-MM-dd"
                    type="date"
                    name="purchase_from_date"
                    v-model="search.purchase_from_date"
                    id="purchase_from_date"
                    placeholder="Purchase From Date"
                    :clearable="true"
                    style="width: 100%"
                  ></DatePicker>
                </div>
              </b-col>
              <b-col md="2">
                <div class="form-group">
                  <DatePicker
                    format="yyyy-MM-dd"
                    type="date"
                    name="purchase_to_date"
                    v-model="search.purchase_to_date"
                    id="purchase_to_date"
                    placeholder="Purchase To Date"
                    :clearable="true"
                    style="width: 100%"
                  ></DatePicker>
                </div>
              </b-col>
              <b-col md="2" class="text-right">
                <div class="form-group">
                  <Button
                    type="primary"
                    class="w-100"
                    @click="ExportPurchasesList"
                    >Export</Button
                  >
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="align-middle text-center">Sl</th>
                  <th>Student Info</th>
                  <th width="260">Financial Info</th>
                  <th class="text-center">Status</th>
                  <th>Course Info</th>
                  <th>Timestamp</th>
                  <th class="align-middle">
                    <label
                      class="custom-control custom-checkbox mb-0 d-inline-block"
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="checkAllDataTables"
                        v-model="selectedAll"
                        @click="selectAll"
                      />
                      <span class="custom-control-label">&nbsp;</span>
                    </label>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(purchase, index) in purchases.data"
                  :key="index"
                  v-if="purchases.data.length"
                >
                  <td class="align-middle text-center">
                    <p>{{ purchases.from + index }}</p>
                  </td>
                  <td class="align-middle">
                    <div v-if="purchase.student">
                      <p class="mb-0">
                        <b>{{ purchase.student.name }}</b>
                      </p>
                      <p class="mb-0" v-if="purchase.student.number">
                        <b>Number : </b>{{ purchase.student.number }}
                      </p>
                      <p class="mb-0" v-if="purchase.student.email">
                        <b>Email : </b>{{ purchase.student.email }}
                      </p>
                      <p class="mb-0" v-if="purchase.batch">
                        <b>Batch : </b>{{ purchase.batch.name }}
                      </p>
                    </div>
                  </td>
                  <td class="align-middle">
                    <p class="mb-0">
                      <b>Total Course Fee : </b> {{ purchase.total_payment }}/-
                    </p>
                    <p class="mb-0">
                      <b>Total Discount : </b> {{ purchase.total_discount }}/-
                    </p>
                    <p class="mb-0">
                      <b>Total Payable Amount : </b>
                      {{ purchase.payable_amount }}/-
                    </p>
                    <p class="mb-0" v-if="purchase.coupon_code">
                      <b>Voucher : </b>
                      {{ purchase.coupon_code }}
                    </p>
                  </td>

                  <td class="align-middle text-center">
                    <span
                      class="badge"
                      style="margin: 3px"
                      :class="
                        purchase.is_complete == true
                          ? 'badge-success'
                          : 'badge-warning'
                      "
                      >{{
                        purchase.is_complete == true
                          ? "Purchase Complete"
                          : "Purchase Pending"
                      }}</span
                    >
                    <span
                      v-if="purchase.payment_method"
                      class="badge"
                      :class="
                        purchase.payment_method == 1
                          ? 'badge-primary'
                          : purchase.payment_method == 2
                          ? 'badge-success'
                          : 'badge-info'
                      "
                      >{{
                        purchase.payment_method == 1
                          ? "SSLCommerz"
                          : purchase.payment_method == 2
                          ? "b-Kash"
                          : "Nogod"
                      }}</span
                    >
                  </td>
                  <td class="align-middle">
                    <p
                      class="mb-0"
                      v-for="(course, index) in purchase.purchase_courses"
                      :key="index"
                      v-if="purchase.purchase_courses.length && course.course"
                    >
                      <b>>> </b> {{ course.course.name }}
                    </p>
                  </td>
                  <td class="align-middle">
                    <p class="mb-0">
                      <b>Created At : </b
                      >{{ purchase.created_at | date_time_format }}
                    </p>
                    <p class="mb-0">
                      <b>Purchase At : </b
                      >{{ purchase.date | date_time_format }}
                    </p>
                  </td>
                  <td class="align-middle">
                    <b-form-checkbox
                      v-model="select.data"
                      :value="purchase.id"
                      name="flavour-3a"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ purchases.from ? purchases.from : 0 }} to
                  {{ purchases.to ? purchases.to : 0 }} of
                  {{ purchases.total ? purchases.total : 0 }}
                  entries</span
                >
              </b-col>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <pagination
                  :data="purchases"
                  @pagination-change-page="getResults"
                  align="right"
                  :show-disabled="true"
                  :limit="5"
                ></pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
  BFormCheckboxGroup,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import _ from "lodash";
export default {
  data() {
    return {
      batchAssignModal: false,
      selected: [],
      search: {
        is_complete: null,
        course_id: null,
        teacher_id: null,
        payment_method: null,
        from_date: "",
        to_date: "",
        purchase_from_date: "",
        purchase_to_date: "",
        search_data: "",
        paginate: 10,
        page: 1,
        batch_id: "",
      },
      batches: [],
      bacth_id: null,
      perPageOptions: [10, 25, 50, 100],
      purchases: {},
      image: this.$store.state.base_url + "images/student/default.svg",

      select: {
        data: [],
        selected: false,
      },
      selectedAll: false,
    };
  },
  mounted() {
    this.$store.dispatch("getActiveCourses");
    this.$store.dispatch("getTeacher");
    this.getResults();
    this.getAllBatches();
  },
  methods: {
    async getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const purchases = await this.callApi(
        "post",
        "/app/purchase/get?page=" + this.search.page,
        this.search
      );
      if (purchases.status == 200) {
        this.purchases = purchases.data.purchases;
      }
    },

    async getAllBatches() {
      const batches = await this.callApi("get", "/app/all/batch");
      if (batches.status == 200) {
        this.batches = batches.data.batches;
      }
    },
    assingBatch() {
      axios
        .post("/app/student/assign/" + this.bacth_id, {
          purchase_id: this.select.data,
        })
        .then((response) => {
          this.s(response.data.message);
        });
    },
    Delete() {
      // Swal.fire({
      //   title: "Are you sure?",
      //   text: "You won't to delete it!",
      //   icon: "warning",
      //   showCancelButton: true,
      //   confirmButtonColor: "#3085d6",
      //   cancelButtonColor: "#d33",
      //   confirmButtonText: "Yes, delete it!",
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     this.DeleteAlert("/app/course/categories/delete", this.select);
      //     this.select.data = [];
      //     this.getResults();
      //   }
      // });
    },

    // Select Data for Delete
    selectAll(event) {
      if (event.target.checked == false) {
        this.select.data = [];
      } else {
        this.select.data = [];
        this.purchases.data.forEach((voucher) => {
          this.select.data.push(voucher.id);
        });
      }
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.select.data = [];
      this.getResults();
    }, 1000),

    // Paginate Data Gate
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },

    ExportPurchasesList() {
      axios
        .post("/app/purchase/export", this.search, {
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Purchases_List.xlsx");
          document.body.appendChild(link);
          link.click();
        });
    },
  },
  watch: {
    "search.from_date": function (val) {
      this.SearchData();
    },
    "search.to_date": function (val) {
      this.SearchData();
    },
    "search.purchase_from_date": function (val) {
      this.SearchData();
    },
    "search.purchase_to_date": function (val) {
      this.SearchData();
    },
    "search.search_data": function (val) {
      this.SearchData();
    },
    "search.bacth_id": function (val) {
      this.SearchData();
    },
    "search.paginate": function (val) {
      this.paginateChange();
    },
    "select.data": function (selected) {
      this.select.selected = selected.length > 0;
      this.selectedAll = selected.length == this.purchases.data.length;
    },
  },
  components: {
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    BFormCheckboxGroup,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
  },
  computed: {
    permission() {
      return this.$store.getters.getPermissions.student;
    },
    courses() {
      return this.$store.getters.getActiveCourses;
    },
    teachers() {
      return this.$store.getters.getTeacher;
    },
  },
};
</script>

<style></style>
